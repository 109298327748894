<template>
  <div style="height: 100%;">
    <app-quote-side-menu :min-width="10"
      :fixed-menu="false"
      :subquotes="value.subQuoteNos"
      :currentsubquote="value.subQuoteNo"
      :current-route-name="$route.name"
      :is-new="value.isNew"
      :read-only-statuses="value.readOnlyStatuses"
      :is-synced="value.isSynced"
      @select-subquote="selectSubQuote">
      <aside class="menu"
        slot="side-menu">
        <div class="menu-label">
          <div class="columns is-gapless">
            <div class="column">
              <span class="title is-6"
                style="white-space: nowrap">
                Quote No: {{ entityName }}
              </span>
            </div>
            <div class="column">
              <span v-if="value.quotingMethod"
                class="tag is-pulled-right tooltip ml-2"
                :data-tooltip="`${quotingMethodDescription} quote`"
                :class="[value.quotingMethod === quotingMethodTypes.Hour ? 'is-info' : 'is-success']">{{ value.quotingMethod }}</span>
            </div>
          </div>
        </div>
        <ul class="menu-list">
          <li>
            <router-link :to="{ name: routeTypes.QuoteHeader.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              exact>
              <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded': $v.estimateJobStartEnd.$error || $v.quoteHeaderGroup.$error || $v.ormHeaderGroup.$error || $v.audaNetHeaderGroup.$error || $v.ormSummaryGroup.$error }"
                :data-badge="$v.estimateJobStartEnd.$error || $v.quoteHeaderGroup.$error || $v.ormHeaderGroup.$error || $v.audaNetHeaderGroup.$error || $v.ormSummaryGroup.$error ? '' : null">
                <span class="has-badge-child-offset2">Header</span>
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: routeTypes.QuoteSummary.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              exact>
              <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded': $v.ormSummaryGroup.$error || $v.estimateJobStartEnd.$error}"
                :data-badge="$v.ormSummaryGroup.$error || $v.estimateJobStartEnd.$error ? '' : null">
                <span class="has-badge-child-offset2">Summary</span>
              </span>
            </router-link>
          </li>
          <li v-if="value.audatex || value.pnet">
            <router-link :to="{ name: routeTypes.QuotePnetAudanet.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              exact>
              <span>
                PNET{{ pnetAudanetMenuText }}
              </span>
            </router-link>
          </li>
          <li v-if="value.orm || false">
            <router-link :to="{ name: routeTypes.QuoteOrm.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              exact>
              <span>
                ORM
              </span>
            </router-link>
          </li>
          <li v-if="value.repx">
            <router-link :to="{ name: routeTypes.QuoteRepx.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              exact>
              <span>
                RepX
              </span>
            </router-link>
          </li>
          <!-- <li v-if="value.repx">
            <router-link :to="{ name: routeTypes.QuoteVehicleDamage.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              exact>
              <span>
                Vehicle Damage
              </span>
            </router-link>
          </li> -->
          <li v-if="value.arnieCount">
            <router-link :to="{ name: routeTypes.QuoteArnie.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              exact>
              <span>
                ARNIE
              </span>
            </router-link>
          </li>
          <li v-if="$company.setting.estimageOnlineEnabled && insurer?.estimage">
            <router-link :to="{ name: routeTypes.QuoteEstImage.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              exact>
              <span class="has-badge-child-offset5">
                EstImage
              </span>
              <span class="has-badge-rounded has-badge-medium has-badge-danger"
                :data-badge="'NEW'" />
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: routeTypes.QuoteRatesMarkups.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              exact>
              <span>
                Rates &amp; Markups
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: routeTypes.QuoteRemarks.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              exact>
              <span>
                <span class="has-badge-child-offset3">
                  Remarks &amp; Notes
                </span>
                <span v-show="remarkCount"
                  class="has-badge-info has-badge-rounded has-badge-medium"
                  :data-badge="remarkCount" />
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: routeTypes.QuoteAnnotations.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              exact>
              <span>
                Annotations
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: routeTypes.QuoteCommunications.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              :class="{ 'is-disabled': value.isNew}"
              exact>
              <span>
                <span class="has-badge-child-offset3">Communications</span>
                <span v-show="unreadCount"
                  class="has-badge-info has-badge-rounded has-badge-medium"
                  :data-badge="unreadCount" />
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: routeTypes.QuoteImages.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              class="is-flex is-justify-content-space-between"
              active-class="is-active"
              :class="{ 'is-disabled': value.isNew}"
              @click.native.self.prevent="goToRoute({ name: routeTypes.QuoteImages.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span>
                <span class="has-badge-child-offset3">Images</span>
                <span v-show="imageCount"
                  class="has-badge-info has-badge-rounded has-badge-medium"
                  :data-badge="imageCount" />
              </span>
              <span class="icon breakout-icon-hover"
                v-if="imageCount > 0"
                @click.prevent="handleClickImageBreakOut"
                v-tippy="{ enabled: true }"
                content="Image Breakout">
                <i class="mdi mdi-arrow-top-right-bold-box-outline mdi-30px" />
              </span>
            </router-link>

          </li>
          <li>
            <router-link :to="{ name: routeTypes.QuoteDocuments.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              :class="{ 'is-disabled': value.isNew}"
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteDocuments.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span>
                <span class="has-badge-child-offset3">Documents</span>
                <span v-show="documentCount"
                  class="has-badge-info has-badge-rounded has-badge-medium"
                  :data-badge="documentCount" />
              </span>
            </router-link>
          </li>
          <li v-if="!value.isAssessmentEnabled">
            <router-link :to="{ name: routeTypes.QuoteInvoice.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              :class="{ 'is-disabled': !enableItemRoutes || value.isNew}"
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteInvoice.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span>
                <span class="has-badge-child-offset3">Invoice</span>
                <span v-if="invoice"
                  class="has-badge-rounded has-badge-medium has-badge-icon-check"
                  :class="getInvoiceBadgeClass('Invoice')"
                  data-badge="" />
              </span>
            </router-link>
          </li>
          <li v-else>
            <router-link :to="{ name: routeTypes.QuoteAssessmentInvoice.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              :class="{ 'is-disabled': !enableItemRoutes || value.isNew}"
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteAssessmentInvoice.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span>
                <span class="has-badge-child-offset3">Invoice</span>
                <span v-if="invoice"
                  class="has-badge-rounded has-badge-medium"
                  :class="[$v.entity.invoices.$error ? 'has-badge-danger has-badge-icon-exclamation' : (invoice.isNew ? 'has-badge-icon-check has-badge-gold' : 'has-badge-icon-check has-badge-success')]"
                  data-badge="" />
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: routeTypes.QuoteExcessInvoice.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              :class="{ 'is-disabled': !enableItemRoutes || value.isNew}"
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteExcessInvoice.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span>
                <span class="has-badge-child-offset3">Excess Invoice</span>
                <span v-if="excessInvoice"
                  class="has-badge-rounded has-badge-medium has-badge-icon-check"
                  :class="getInvoiceBadgeClass('Excess')"
                  data-badge="" />
              </span>
            </router-link>
          </li>
          <li v-if="value.isAssessmentEnabled">
            <router-link :to="{ name: routeTypes.AuthoriseView.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              :class="{ 'is-disabled': !enableItemRoutes}"
              @click.native.prevent="goToRoute({ name: routeTypes.AuthoriseView.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span class="has-badge-child-offset5">
                Assessments
              </span>
              <span class="has-badge-rounded has-badge-medium has-badge-danger"
                :data-badge="'NEW'" />
            </router-link>
          </li>
        </ul>
        <p class="menu-label">
          Items
        </p>
        <ul class="menu-list">
          <li v-if="false">
            <router-link :to="{ name: routeTypes.QuoteItemsAuthorise.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              :class="{ 'is-disabled': !enableItemRoutes}"
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteItemsAuthorise.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span>
                Old Authorise
              </span>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: routeTypes.QuoteBuilder.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              :class="{ 'is-disabled': !enableItemRoutes}"
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteBuilder.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span>
                <span class="has-badge-child-offset3">Quote Items</span>
                <span class="has-badge-rounded has-badge-medium has-badge-danger"
                  :data-badge="'NEW'" />
              </span>
            </router-link>
          </li>

          <li v-if="$company.setting.partsControlEnabled">
            <router-link :to="{ name: routeTypes.QuotePartsControl.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              :class="{ 'is-disabled': !enableItemRoutes, 'is-active': isPartsControlActive}"
              @click.native.prevent="goToRoute({ name: routeTypes.QuotePartsControl.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span>
                <span class="has-badge-child-offset5">Parts Control</span>
                <span class="has-badge-rounded has-badge-medium has-badge-danger"
                  :data-badge="'NEW'" />
              </span>
            </router-link>
          </li>

          <li v-if="!!others.length">
            <router-link :to="{ name: routeTypes.QuoteOtherLabour.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              :class="{ 'is-disabled': !enableItemRoutes}"
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteOtherLabour.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span>
                <span>
                  <span class="has-badge-child-offset3">Other Labour</span>
                  <span v-show="others.length"
                    class="has-badge-rounded has-badge-medium"
                    :class="getBadgeClass('others')"
                    :data-badge="others.length" />
                </span>
              </span>
            </router-link>
          </li>

        </ul>
      </aside>
    </app-quote-side-menu>
  </div>
</template>

<script>
import AppQuoteSideMenu from '@/components/AppQuoteSideMenu'
import QuoteRoutes from './route-types'
import { QuotingMethodTypes } from '@/enums'
import Guid from '@/components/Guid'
import QuoteService from './QuoteService'
import { EventHubTypes, InvoiceTypes } from '@/enums'
import { SmsService, WhatsAppService, EmailService } from '@/services'
import { QuoteItemDifferenceMixin } from './mixins'

export default {
  name: 'QuoteSideMenu',
  inject: ['$vv'],
  components: {
    AppQuoteSideMenu
  },
  mixins: [QuoteItemDifferenceMixin],
  props: {
    value: null,
    entityName: {
      type: String,
      default: ''
    },
    pricePartsReturnRoute: null,
    insurer: null
  },
  data() {
    return {
      imageCount: 0,
      documentCount: 0,
      unreadInterval: null,
      unreadCount: 0
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    routeTypes() {
      return QuoteRoutes
    },
    quotingMethodTypes() {
      return QuotingMethodTypes
    },
    quotingMethodDescription() {
      if (this.value.quotingMethod === this.quotingMethodTypes.Dollar) {
        return Object.getOwnPropertyNames(this.quotingMethodTypes)[0]
      } else if (this.value.quotingMethod === this.quotingMethodTypes.Hour) {
        return Object.getOwnPropertyNames(this.quotingMethodTypes)[1]
      }
    },
    enableItemRoutes() {
      return Guid.validGuid(this.value.insurerId) && Guid.validGuid(this.value.vehicleId) && !this.$v.quoteHeaderGroup.$error
    },
    pnetAudanetMenuText() {
      if (this.value.isAudaNet) {
        return this.value.audatex && this.value.audatex.taskType ? `/${this.value.audatex.taskType}` : '/AudaNet'
      } else {
        return ''
      }
    },
    others() {
      return this.value.others.filter((i) => !i.deleted && i.itemType !== 'MECH')
    },
    invoice() {
      return this.value.invoices.find((i) => i.invoiceType === InvoiceTypes.Quote && !i.deleted)
    },
    excessInvoice() {
      return this.value.invoices.find((i) => (i.invoiceType === InvoiceTypes.Excess || i.invoiceType === InvoiceTypes.OwnerContribution) && !i.deleted)
    },
    isCustomerVehicleDeleted() {
      return (
        this.$v.quoteCustomer.active.required &&
        this.$v.quoteVehicle.isActive.required &&
        this.$v.vehicleCustomerIsActiveGroup.$dirty &&
        this.$v.vehicleCustomerIsActiveGroup.$error
      )
    },
    remarkCount() {
      return this.value && this.value.remarks ? this.value.remarks.filter((r) => !r.isDeleted).length : 0
    },
    isPartsControlActive() {
      return (
        this.$route.name === QuoteRoutes.QuotePartsControl.name ||
        (this.$route.name === QuoteRoutes.QuotePriceParts.name && this.pricePartsReturnRoute === QuoteRoutes.QuotePartsControl.name)
      )
    },
    isPartsActive() {
      return (
        this.$route.name === QuoteRoutes.QuotePart.name ||
        (this.$route.name === QuoteRoutes.QuotePriceParts.name && this.pricePartsReturnRoute === QuoteRoutes.QuotePart.name)
      )
    }
  },
  created() {
    this.getImageCount()
    this.getDocumentCount()
    this.getUnreadCount()
    this.autoRefreshUnreadCount()
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.ImageCountChanged, (count) => (this.imageCount = count))
    this.$eventHub.$on(EventHubTypes.DocumentCountChanged, (count) => (this.documentCount = count))
    this.$v.vehicleCustomerIsActiveGroup.$touch()
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.ImageCountChanged)
    this.$eventHub.$off(EventHubTypes.DocumentCountChanged)
    clearInterval(this.unreadInterval)
  },
  methods: {
    getBadgeClass(type) {
      let classes = []

      let syncRequired = false
      for (var item of this[type]) {
        if (this.isLineDifferentFromLastCommunication(item)) {
          syncRequired = true
          break
        }
      }

      if (this.$v.entity[type].$error) {
        classes.push('has-badge-danger')
      } else if (syncRequired) {
        classes.push('has-badge-warning')
      } else {
        classes.push('has-badge-info')
      }
      return classes
    },
    getInvoiceBadgeClass(type) {
      let classes = []

      if (type === 'Invoice') {
        if (this.$v.entity.invoices.$error) {
          let errors = Object.values(this.$v.entity['invoices'].$each.$iter).filter((r) => {
            return r.$anyError
          })
          errors.forEach((item) => {
            if (item.$model.invoiceType === InvoiceTypes.Quote) {
              classes.push('has-badge-danger')
            }
          })
        } else if (this.invoice.isNew) {
          classes.push('has-badge-gold')
        } else {
          classes.push('has-badge-success')
        }
      }
      if (type === 'Excess') {
        if (this.$v.entity.invoices.$error) {
          let errors = Object.values(this.$v.entity['invoices'].$each.$iter).filter((r) => {
            return r.$anyError
          })
          errors.forEach((item) => {
            if (item.$model.invoiceType === InvoiceTypes.Excess || item.$model.invoiceType === InvoiceTypes.OwnerContribution) {
              classes.push('has-badge-danger')
            }
          })
        } else if (this.excessInvoice.isNew) {
          classes.push('has-badge-gold')
        } else {
          classes.push('has-badge-success')
        }
      }
      return classes
    },
    selectSubQuote(subQuoteNo) {
      this.$emit('select-subquote', subQuoteNo)
    },
    goToRoute(route) {
      if (
        (this.enableItemRoutes &&
          route.name !== QuoteRoutes.QuoteImages.name &&
          route.name !== QuoteRoutes.QuoteInvoice.name &&
          route.name !== QuoteRoutes.QuoteExcessInvoice.name) ||
        (route.name === QuoteRoutes.QuoteImages.name && !this.value.isNew) ||
        (route.name === QuoteRoutes.QuoteInvoice.name && !this.value.isNew) ||
        (route.name === QuoteRoutes.QuoteExcessInvoice.name && !this.value.isNew)
      ) {
        this.$router.push(route)
      }
    },
    async getImageCount() {
      const imageIds = await QuoteService.getImageIds(this.value.quoteId)
      this.imageCount = imageIds.length
    },
    async getDocumentCount() {
      const attachments = await QuoteService.getQuoteAttachments(this.value.quoteId)
      let count = Object.values(attachments.data).reduce((total, row) => total + Object.values(row.quoteAttachments).length, 0)
      this.documentCount = count
    },
    async getUnreadCount() {
      const smsreadCount = await SmsService.getUnreadCount(this.value.quoteId)
      const wareadCount = await WhatsAppService.getUnreadCount(this.value.quoteId)
      const emailreadCount = await EmailService.getUnreadCount(this.value.quoteId)
      this.unreadCount = smsreadCount + wareadCount + emailreadCount
    },
    autoRefreshUnreadCount() {
      this.unreadInterval = setInterval(async () => {
        this.getUnreadCount()
      }, 30000)
    },
    handleClickImageBreakOut() {
      const baseUri = process.env.VUE_APP_BASE_URI === '/' ? process.env.VUE_APP_BASE_URI : `${process.env.VUE_APP_BASE_URI}/`
      const newWindow = window.open(`${baseUri}quotes/${this.value.quoteId}/imagesbreakout?uninterruptedview=true`, 'Imageviewer', {
        width: 1600,
        height: 900,
        left: 0,
        top: 0,
        fullscreen: 'yes',
        title: 'Image Breakout'
      })
      if (newWindow) {
        const timer = setInterval(() => {
          if (newWindow.closed) {
            clearInterval(timer)
            if (this.$router.currentRoute.name == QuoteRoutes.QuoteImages.name){
              this.$emit('reload-quote-images')
            }
          }
        }, 500)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.is-disabled {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  // pointer-events: none;
  a {
    display: inline-block;
    // pointer-events: none;
    text-decoration: none;
  }
}

span.superscript {
  vertical-align: super;
  font-size: smaller;
}

span.has-badge-child {
  margin-right: 1em;
}

.has-badge-icon {
  &::after {
    font-family: 'Material Design Icons';
    content: '';
    //content: attr(data-badge);
    // content: attr(data-test);
  }
}

.has-badge-icon-check {
  &::after {
    font-family: 'Material Design Icons';
    content: '\f012c';
    //content: attr(data-test);
  }
}

.has-badge-icon-exclamation {
  &::after {
    font-family: 'Material Design Icons';
    content: '\f0205';
  }
}
</style>
